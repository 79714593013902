import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types/auth';
import v1 from 'api/v1';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { openSnackbar } from 'api/snackbar';
import { SnackbarProps } from 'types/snackbar';
import SafeReactMarkdown from 'components/SafeReactMarkdown';

const SignDialog = ({ open, onClose }: any) => {
  const { data } = v1.getCompanyContract(open);
  const signContract = async () => {
    const response = await v1.signCompanyContract();
    if (response?.data?.success) {
      openSnackbar({
        open: true,
        message: response?.data?.message,
        variant: 'alert',
        alert: {
          color: 'success',
        },
      } as SnackbarProps);
      onClose('/');
    } else {
      openSnackbar({
        open: true,
        message: response?.data?.message,
        variant: 'alert',
        alert: {
          color: 'error',
        },
      } as SnackbarProps);
    }
  };

  return (
    <Dialog open={open} scroll="paper" aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
      <Grid container spacing={2} justifyContent="space-between" alignItems="center">
        <Grid item>
          <DialogTitle>Read and understand the contract</DialogTitle>
        </Grid>
      </Grid>

      <DialogContent dividers>
        <Grid container spacing={1.25} sx={{ p: 2 }} direction="column">
          <SafeReactMarkdown
            components={{
              h1: ({ node, ...props }) => (
                <>
                  <h1>{props.children}</h1>
                  <br />
                </>
              ),
              h2: ({ node, ...props }) => (
                <>
                  <h2>{props.children}</h2>
                  <br />
                </>
              ),
            }}
          >
            {data?.data.data.contract}
          </SafeReactMarkdown>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={signContract} sx={{ mr: 1 }}>
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default function CustomerSignGuard({ children }: GuardProps) {
  const { user } = useAuth();
  if (user?.role !== 'customer') return children;
  const { data, isFetching, refetch } = v1.getCompany();
  return (
    <>
      {children}
      <SignDialog open={!data?.data.data.is_contract_signed && !isFetching} onClose={refetch} />
    </>
  );
}
