import { useTheme } from '@mui/material';
import v1 from 'api/v1';
import saveAs from 'file-saver';
import { useEffect, useState } from 'react';
// eslint-disable-next-line no-restricted-imports
import ReactMarkdown, { Options } from 'react-markdown';
import SyntaxHighlighter from 'react-syntax-highlighter';
import remarkBreaks from 'remark-breaks';

const FilePreviewBox = ({ id, name }: any) => {
  const downloadFile = async () => {
    try {
      if (id) {
        const response = await v1.getFile({ id: id + '' });
        const url = response?.data?.data?.url || '';

        if (url) {
          const response = await fetch(url);
          const blob = await response.blob(); // Get the file content as a Blob
          saveAs(blob, name); // This will trigger the download with the specified file name
        }
      }
    } catch (err) {
      console.error('Error fetching file:', err);
    }
  };

  const theme = useTheme();

  return (
    <div
      style={{
        border: `1px solid ${theme.palette.divider}`, // Use theme's divider color
        padding: theme.spacing(1), // Use theme's spacing unit
        borderRadius: theme.shape.borderRadius, // Use theme's border radius
        backgroundColor: theme.palette.background.paper, // Use theme's background color
        display: 'inline-block',
        cursor: 'pointer',
      }}
      onClick={downloadFile}
    >
      <strong>📄 {name}</strong>
    </div>
  );
};

const ImgViewBox = ({ id, alt }: { id: string | null; alt?: string }) => {
  const [imageUrl, setImageUrl] = useState<string>(''); // State to store the image URL
  const [loading, setLoading] = useState<boolean>(true); // State for loading indicator
  const [error, setError] = useState<string | null>(null); // State for error handling

  useEffect(() => {
    const fetchImage = async () => {
      setLoading(true); // Start loading
      try {
        if (id) {
          const response = await v1.getFile({ id });
          const url = response?.data?.data?.url || '';
          setImageUrl(url); // Set the image URL
        }
      } catch (err) {
        console.error('Error fetching image:', err);
        setError('Failed to load image');
      } finally {
        setLoading(false); // End loading
      }
    };

    fetchImage();
  }, [id]); // Trigger when 'id' changes

  if (loading) return <div>Loading...</div>; // Show loading indicator
  if (error) return <div>{error}</div>; // Show error message

  return <img src={imageUrl} alt={alt} style={{ maxWidth: '100%' }} />;
};

const getFileId = (url: string): string | null => {
  const regex = /\/file\/(\d+)(?=[\?\/#]|$)/; // Match "/file/number" and ensure it's before query or fragment
  const match = url.match(regex);
  return match ? match[1] : null; // Return the matched number (file ID)
};

export default ({ children, components, ...restProps }: Options) => (
  <ReactMarkdown
    remarkPlugins={[remarkBreaks]}
    components={{
      img: ({ src, alt }) => {
        if (src) {
          const url = new URL(src);
          if (['localhost:3000', 'gamma.app.hackerest.com', 'app.hackerest.com'].includes(url.host)) {
            const fileType = url.searchParams.get('type') || 'img';
            if (fileType === 'file') {
              const fileName = url.searchParams.get('name') || 'download-file';
              return <FilePreviewBox name={fileName} id={getFileId(src)} />;
            }
            return <ImgViewBox id={getFileId(src)} alt={alt} />;
          }
        }
        return <img src={src} alt={alt} style={{ maxWidth: '100%' }} />;
      },
      a: ({ node, ...props }) => (
        <a {...props} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      ),
      code({ node, children, className, ...props }) {
        const match = /language-(\w+)/.exec(className || '');
        return match ? (
          // @ts-ignore - Ignore the type incompatibility error here
          <SyntaxHighlighter {...props} children={String(children).replace(/\n$/, '')} language={match[1]} />
        ) : (
          <code {...props} className={className}>
            {children}
          </code>
        );
      },
      h1: ({ node, ...props }) => (
        <>
          <strong>{props.children}</strong>
          <br />
        </>
      ),
      h2: ({ node, ...props }) => (
        <>
          <strong>{props.children}</strong>
          <br />
        </>
      ),
      h3: ({ node, ...props }) => (
        <>
          <strong>{props.children}</strong>
          <br />
        </>
      ),
      h4: ({ node, ...props }) => (
        <>
          <strong>{props.children}</strong>
          <br />
        </>
      ),
      h5: ({ node, ...props }) => (
        <>
          <strong>{props.children}</strong>
          <br />
        </>
      ),
      h6: ({ node, ...props }) => (
        <>
          <strong>{props.children}</strong>
          <br />
        </>
      ),
      blockquote: ({ node, ...props }) => (
        <blockquote style={{ borderLeft: '0.25em solid #888', marginLeft: 0, padding: '0 1em', color: '#888' }} {...props} />
      ),
      ...components,
    }}
    {...restProps}
  >
    {children}
  </ReactMarkdown>
);
